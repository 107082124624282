import { Button, Col, DatePicker, Form, Input, Row, Select,  TimePicker} from "antd"
import axios from "axios"
import {  useEffect, useState } from "react"
import { DevanningDrawer } from "../container/devanningDrawer"
import { useRecoilState } from "recoil"
import { deliveredTableLoadingState, deliverySearchValuesState, dispatchedTableDataState } from "./recoil.state"
import { DispatchedPageTable } from "./deliveredPageTable"
import { deliveredItemListUrl, deliveredItemsOfCustomer } from "./api"
import { roleState } from "../stateManage"
import { SummaryDrawer } from "./summaryDrawer"
import { EditDeliveryItemsDrawer } from "./bulkEditItems"
import _ from 'lodash'

type ShowColumn = {
    shipper: string,
    container: string,
    ETD: string,
    ETA: string,
    type: string
    invoiceId: string,
    key: string
}
type DataType = {
    key: string
}
export const DeliveryPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [shippers, setShippers] = useState([])
    const [containerIds, setContainerIds] = useState([])
    const [customers, setCustomers] = useState([])
    const [deliveryMethod, setDeliveryMethod] = useState([])
    const [bills, setBills] = useState([])
    const [POs, setPOs] = useState([])
    const [locations, setlocations] = useState([])
    const [tableData, setTableData] = useRecoilState(dispatchedTableDataState)
    const [deliveredTableLoading, setDeliveredTableLoading] = useRecoilState(deliveredTableLoadingState)
    const [deliverySearchValues, setDeliverySearchValues] = useRecoilState(deliverySearchValuesState)
    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.loadedQTY = Number(values.loadedQTY)
        var v = _.omitBy(values, v=>v==undefined || v==null || v=='')
        setDeliverySearchValues(v)
    }

    const resetClick = () => {
        setDeliverySearchValues({})
    }

    useEffect(() => {
        if(role=='admin'){
            axios.get(deliveredItemListUrl).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
                res.data.deliveryMethod=res.data.deliveryMethod.filter(it=>it)
                setDeliveryMethod(res.data.deliveryMethod.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
                setlocations(res.data.locations.map(it => ({ label: it, value: it })))
                setShippers(res.data.shippers.map(it => ({ label: it, value: it })))
    
            }).catch(e => {
    
            })
        }else if(role=='user'){
            axios.get(deliveredItemListUrl).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
                res.data.deliveryMethod=res.data.deliveryMethod.filter(it=>it)
                setDeliveryMethod(res.data.deliveryMethod.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
                setlocations(res.data.locations.map(it => ({ label: it, value: it })))
    
            }).catch(e => {
    
            })
        }else if(role=='customer'){
            axios.get(deliveredItemsOfCustomer).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
            }).catch(e => {
    
            })
        }else{}
        

        if (Object.keys(deliverySearchValues).length > 0) {
            form.setFieldsValue(deliverySearchValues)
        }

    }, [])
    var colSpan = 5
    const adminSearchOptions = ['shipper', 'containerId', 'billNumber', 'deliveryMethod', 'customer', 'PO', 'loadedQTY', 'deliveryDate', 'deliveryTime', 'location']
    var searchOptions = []
    if (role == 'admin') {
        searchOptions = adminSearchOptions
    }else if(role == 'user'){
        _.remove(adminSearchOptions, (it) => it =='shipper' || it=='location' || it=='deliveryMethod')
    }else if(role == 'customer'){
        _.remove(adminSearchOptions, (it) => it=='shipper' || it=='location' || it=='customer' || it=='deliveryMethod')
    }else{
        searchOptions = []
    }
    searchOptions=adminSearchOptions
    return <>
        <Form
          labelCol={{ span: 10 }}
          // wrapperCol={{ span: 20 }}
          labelAlign="right"
            name="search"
            onFinish={onFinish}
            form={form}
            >
            <Row>
            <Col span={searchOptions.includes('shipper') ? colSpan : 0} >
                    <Form.Item name="shipper" label="Forwarder">
                        <Select
                            showSearch
                            allowClear={true}
                            options={shippers}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('containerId') ? colSpan : 0} >
                    <Form.Item name="containerId" label="CNTR/AWB#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={containerIds}
                        />
                    </Form.Item>
                </Col>
               
                <Col span={searchOptions.includes('billNumber') ? colSpan : 0}>
                    <Form.Item name="billNumber" label="Bill#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={bills}
                        />
                    </Form.Item>
                </Col>
                
                <Col span={searchOptions.includes('deliveryMethod') ? colSpan : 0} >
                    <Form.Item name="deliveryMethod" label="Del. Method">
                        <Select
                            showSearch
                            allowClear={true}
                            options={deliveryMethod}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('customer') ? colSpan : 0} >
                    <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                        />
                    </Form.Item>
                </Col>
               
                <Col span={searchOptions.includes('PO') ? colSpan : 0}>
                    <Form.Item name="PO" label="PO#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={POs}
                        />
                    </Form.Item>
                </Col>
                <Col  span={searchOptions.includes('loadedQTY') ? colSpan : 0}>
                    <Form.Item name="loadedQTY" label="Del. Q’ty">
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryDate') ? colSpan : 0} >
                    <Form.Item name="deliveryDate" label="Del. Date" >
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col  span={searchOptions.includes('deliveryTime') ? colSpan : 0}>
                    <Form.Item name="deliveryTime" label="Del. Time">
                        <TimePicker format={'HH:mm'} minuteStep={10} changeOnBlur={true} showNow={false} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('location') ? colSpan : 0} >
                    <Form.Item name="location" label="Location">
                        <Select
                            showSearch
                            allowClear={true}
                            options={locations}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" >
                {/* <Col >
                    <Button type="primary" onClick={optionsClick} icon={<SettingOutlined />} size={"middle"} />
                </Col> */}
                <Col  >
                    <Form.Item style={{ marginRight: '20px' }} >
                        <Button type="primary" htmlType="submit">Search</Button >
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item   >
                        <Button onClick={resetClick} type="primary" htmlType="reset">Reset</Button >
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <DispatchedPageTable />
        <DevanningDrawer />
        <SummaryDrawer />
        <EditDeliveryItemsDrawer />
    </>
}


