import { Button, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select, Space, Table, TimePicker, Typography, message } from "antd"
import axios from "axios"
import { SetStateAction, useEffect, useState } from "react"
import { DevanningDrawer } from "../container/devanningDrawer"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { devannedItemList, devannedItemsOfCustomer, devannedSearchUrl } from "./api"
import { ColumnsType } from "antd/es/table"
import { devannedTableDataState, devannedTableLoadingState, inHousedSearchValuesState, locationsState } from "./recoil.state"
import { instructionOptionState, roleState } from "../stateManage"
import { getShippersUrl } from "../apiInfo"
import { DevannedPageTable } from "./inhousedPageTable"
import { DispatchEditModel } from "./deliveryEditModel"
import { DoSelectedDeliveryItems } from "./bulkDeliveryItems"
import dayjs from 'dayjs';
import { EditModel } from "./editModel"
import { EditDeliveryItems } from "./bulkEditItems"
import _ from 'lodash'
import { DoSelectedTransferItems } from "./bulkTransferItems"

type ShowColumn = {
    shipper: string,
    container: string,
    ETD: string,
    ETA: string,
    type: string
    invoiceId: string,
    key: string
}
type DataType = {
    key: string
}
export const DevannedPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [shippers, setShippers] = useState([])
    const [containerIds, setContainerIds] = useState([])
    const [customers, setCustomers] = useState([])
    const [bills, setBills] = useState([])
    const [POs, setPOs] = useState([])
    const [tableData, setTableData] = useRecoilState(devannedTableDataState)
    const [locations, setLocations] = useRecoilState(locationsState)
    const [inHousedSearchValues, setInHousedSearchValues] = useRecoilState(inHousedSearchValuesState)

    const [form] = Form.useForm();


    const onFinish = (values) => {
        values.loadedQTY = Number(values.loadedQTY)
        var v = _.omitBy(values, v => v == undefined || v == null || v == '')
        setInHousedSearchValues(v)
    }

    const resetClick = () => {
        setInHousedSearchValues({})
    }

    useEffect(() => {
        if(role=='admin'){
            axios.get(devannedItemList).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
                setLocations(res.data.locations.map(it => ({ label: it, value: it })))
                if (role == 'admin') {
                    setShippers(res.data.shippers.map(it => ({ label: it, value: it })))
                }
    
            }).catch(e => {
    
            })
        }else if(role=='user'){
            axios.get(devannedItemList).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
                setLocations(res.data.locations.map(it => ({ label: it, value: it })))
    
            }).catch(e => {
    
            })

        }else if(role=='customer'){
            axios.get(devannedItemsOfCustomer).then(res => {
                setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
                setBills(res.data.bills.map(it => ({ label: it, value: it })))
                setPOs(res.data.POs.map(it => ({ label: it, value: it })))
            }).catch(e => {
    
            })
        }else{

        }

        if (Object.keys(inHousedSearchValues).length > 0) {
            form.setFieldsValue(inHousedSearchValues)
        }


    }, [])
    var colSpan = 5
    const adminSearchOptions = ['shipper', 'containerId', 'billNumber', 'customer', 'PO', 'loadedQTY', 'deliveryDate', 'deliveryTime', 'createdAt', 'location']
    var searchOptions = []
    if (role == 'admin') {
        searchOptions = adminSearchOptions
    }else if(role == 'user'){
        _.remove(adminSearchOptions, (it) => it =='shipper' || it=='location')
    }else if(role == 'customer'){
        _.remove(adminSearchOptions, (it) => it=='shipper' || it=='location' || it=='customer')
    }else{
        searchOptions = []
    }
    searchOptions=adminSearchOptions
    return <>
        <Form
            labelCol={{ span: 10 }}
            // wrapperCol={{ span: 20 }}
            labelAlign="right"
            name="devannedForm"
            onFinish={onFinish}
            form={form}
        >
            <Row>
                <Col span={searchOptions.includes('shipper') ? colSpan : 0} >
                    <Form.Item name="shipper" label="Forwarder">
                        <Select
                            showSearch
                            allowClear={true}
                            options={shippers}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('containerId') ? colSpan : 0} >
                    <Form.Item name="containerId" label="CNTR/AWB#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={containerIds}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('billNumber') ? colSpan : 0} >
                    <Form.Item name="billNumber" label="Bill#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={bills}
                        />
                    </Form.Item>
                </Col>


                <Col span={searchOptions.includes('customer') ? colSpan : 0} >
                    <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                            mode='multiple'
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('PO') ? colSpan : 0}>
                    <Form.Item name="PO" label="PO#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={POs}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('loadedQTY') ? colSpan : 0}>
                    <Form.Item name="loadedQTY" label="Loaded Q’ty">
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryDate') ? colSpan : 0} >
                    <Form.Item name="deliveryDate" label="Est. Del. Date" >
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryTime') ? colSpan : 0}>
                    <Form.Item name="deliveryTime" label="Est. Del. Time">
                        <TimePicker format={'HH:mm'} minuteStep={10} changeOnBlur={true} showNow={false} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('createdAt') ? colSpan : 0}>
                    <Form.Item name="createdAt" label="Dev. Date">
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('location') ? colSpan : 0}>
                    <Form.Item name="location" label="Location">
                        <Select
                            allowClear={true}
                            options={locations}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" >
                {/* <Col >
                    <Button type="primary" onClick={optionsClick} icon={<SettingOutlined />} size={"middle"} />
                </Col> */}
                <Col  >
                    <Form.Item style={{ marginRight: '20px' }} >
                        <Button type="primary" htmlType="submit">Search</Button >
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item   >
                        <Button onClick={resetClick} type="primary" htmlType="reset">Reset</Button >
                    </Form.Item>
                </Col>

            </Row>
        </Form>
        <DevannedPageTable />
        <DispatchEditModel />
        <EditModel />
        <DoSelectedDeliveryItems />
        <DoSelectedTransferItems />
        <EditDeliveryItems />
    </>
}


