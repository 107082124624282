import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker,
} from '@ant-design/pro-form';
import { Form, message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containerDetailDrawerIsOpenState, containerPageActionListState, detailEditModelIsOpenState, focusContainerDetailRecordState, focusContainerRecordState } from '../recoil.state';
import axios from 'axios';
import { saveContainerDetailEditUrl } from '../api';
import { useEffect } from 'react';
import { ContainerAction } from '../type';

export const DetailEditModel = () => {
    const [form] = Form.useForm();
    const [focusDetailRecord, setFocusDetailRecord] = useRecoilState(focusContainerDetailRecordState)
    const [detailEditModelIsOpen,setDetailEditModelIsOpen] = useRecoilState(detailEditModelIsOpenState)
    const focusContainerRecord = useRecoilValue(focusContainerRecordState)
    const [containerPageActionList, setContainerPageActionList] = useRecoilState(containerPageActionListState);
    const [containerDetailDrawerIsOpen, setContainerDetailDrawerIsOpenState] = useRecoilState(containerDetailDrawerIsOpenState)
    const containerDetailEditSave = (values) => {
        axios.put(saveContainerDetailEditUrl, {
            values, id: focusDetailRecord._id 
        }).then(res => {
            message.success('Edit Success')
            setContainerDetailDrawerIsOpenState(false)
            //更新表格
            var containerPageActions: ContainerAction[] = JSON.parse(JSON.stringify(containerPageActionList));
            containerPageActions.push('detailEdit')
            setContainerPageActionList(containerPageActions)
        }).catch(e => {

        }).finally(() => {


        })
    }
    useEffect(() => {
        form.setFieldsValue({ ...focusDetailRecord })
    }, [detailEditModelIsOpen])
    return (
        <ModalForm
            open={detailEditModelIsOpen}
            title={`PO# ${focusDetailRecord.PO}`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(p) => setDetailEditModelIsOpen(p)}
            submitTimeout={2000}
            onFinish={async (values) => {
                containerDetailEditSave(values)
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText
                    width="md"
                    name="PO"
                    label="PO#"
                    placeholder=""
                    initialValue={focusDetailRecord.PO}
                />
                <ProFormText
                    width="md"
                    name="customer"
                    label="Customer"
                    placeholder=""
                    initialValue={focusDetailRecord.customer}
                />
                <ProFormDigit
                    width="md"
                    name="loadedQTY"
                    label="loadedQTY"
                    placeholder=""
                    initialValue={focusDetailRecord.loadedQTY}
                   
                />
                <ProFormDigit
                    width="md"
                    name="totalQTY"
                    label="totalQTY"
                    placeholder=""
                    initialValue={focusDetailRecord.totalQTY}
                />

                <ProFormDatePicker name="deliveryDate" label="deliveryDate" initialValue={focusDetailRecord.deliveryDate} />
                <ProFormTimePicker name="deliveryTime" fieldProps={{ format: 'HH:mm', changeOnBlur: true, minuteStep: 10 }} label="deliveryTime" initialValue={focusDetailRecord.deliveryTime} />


            </ProForm.Group>
            <ProForm.Group>
                <ProFormTextArea label="ChinaNote" name="PO_chinaNotes" fieldProps={{ rows: 7, cols: 35 }} initialValue={focusDetailRecord.ChinaNote} />
                <ProFormTextArea label="USNote" name="PO_US_Notes" fieldProps={{ rows: 7, cols: 35 }} initialValue={focusDetailRecord.USNote} />

            </ProForm.Group>
        </ModalForm>
    );
};